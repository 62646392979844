import React from 'react'
import { connect } from 'react-redux'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'
import { Accordion } from '../components/Accordion'
import ListingStyle from '../styles/pages/collaborationCategory.module.scss'
import CollaborationsFilters from '../components/Filters/CollaborationsFilters'
import { handleModalCta } from '../actions/modal'
import Cta from '../components/Cta'


const Collaborations = ({ pageContext, data, handleModalCta, modalcta, location }) => {
  
  const staticPrismicTexts = data.staticText.edges[0].node.data.texts;
  const uid = data.prismicFeatureFiltersCollaborations.uid;
  const pageData = data.prismicFeatureFiltersCollaborations.data;
  const _t = (str) => {
    return staticPrismicTexts[staticPrismicTexts.findIndex(item => item.key_text === str)].text.text;
  };
  
  /* get all collab items */
  const _collaborationsItems = data.collaborationsItems.edges.map((i) => {
    return {
      uid: i.node.uid,
      logo: i.node.data.logo.url,
      title: i.node.data.title.text,
      filtersList: i.node.data.filters.length > 0 && i.node.data.filters.filter(f => f.filter !== null).map((_i) => {
        return _i.filter.document[0].data.anchor
      })
    }
  });
  
  const collaborationsItems = _collaborationsItems.filter(f => f.filtersList !== false).filter((i) => {
    return i.filtersList.indexOf(uid) > 0;
  })
  let accordionData = [];
  let rows = [];
  
  const colsToRows = () => {
    let rows = [];
    for (let i = 1; i < 6; i++) {
      let row = [];
      for (let j = 0; j < pageData.table_columns.length; j++) {
        if (pageData.table_columns[j][`row_${i}_text`] !== undefined) {
          row.push({
            ...pageData.table_columns[j][`row_${i}_text`], 
            label_column: pageData.table_columns[j].label_column,
            color: pageData.table_columns[j].color
          })
        }
      }
      rows.push(row)
    }
    return rows;
  }

  if (pageData.table_columns.length > 0) {
    accordionData = pageData.table_columns.filter(i => !i.label_column).map((col, index) => {
      return {
        title: col.header.text,
        subTitle: col.anchor_title.text,
        paragraph: col.anchor_description.html,
        color: col.color,
        anchor: `col-${index}`
      }
    });
    rows = colsToRows();
  }

  return (
    <Layout
      context={pageContext}
      pageTitle={pageContext.title}
      _t={staticPrismicTexts}
    >
      <SEO
        pageTitle={pageData.title5}
        ogImage={pageData.image.url}
        pageDescription={pageData.description}
        context={pageContext}
      />

      <div className={`page ${ListingStyle.collaborationsCategory}`}>
        <section className="regular">
          {
            pageData.title.text && (
              <div className="page-head no-custom-style">
                <h1>{pageData.title.text}</h1>
                {
                  pageData.intro && (
                    <div
                      className="page-header-text"
                      dangerouslySetInnerHTML={{
                        __html: pageData.intro.html,
                      }}
                    ></div>
                  )
                }
              </div>
            )
          }
          
          {
            pageData.list2.length > 1 && (
              <React.Fragment>
                {
                  pageData.banner.url && (
                    <div 
                      className={`${ListingStyle.introBanner}`}
                      style={{
                        backgroundImage: `url(${pageData.banner.url})`
                      }}
                    ></div>
                  )
                }
                <div className={`${ListingStyle.introHeader}`}>
                  <h2>{pageData.title1.text}</h2>
                  <div className="container">
                    <div className="row">
                      {pageData.list2.map((item, index) => {
                        return (
                          <div className={`col-md-4 ${ListingStyle.benefitsCards}`} key={`benefit-${index}`}>
                            <div>
                              <h3>{item.title2.text}</h3>
                              <div dangerouslySetInnerHTML={{__html: item.description.html}}></div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className={`${ListingStyle.cta}`}>
                      <Cta 
                        kind={pageData.cta_action_key}
                        label={pageData.cta_title.text}
                        pageContext={pageContext}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          }
          
          {
            (pageData.title_features.text && pageData.list_features.length > 1) && (
              <section>
                <div className="container">
                  <h2>{pageData.title_features.text}</h2>
                  <div className={`${ListingStyle.intro}`} dangerouslySetInnerHTML={{ __html: pageData.intro_features.html}}></div>
                  <div className={`row ${ListingStyle.strategies}`}>
                    <div className="col-md-12">
                      {pageData.list_features.map((i, index) => {
                        return (
                          <div key={`process-${index}`}>
                            <h3>{i.title_features_list.text}</h3>
                            <div dangerouslySetInnerHTML={{ __html: i.description_features_list.html}}></div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  {
                    pageData.cta_action_key_features && (
                      <div className={`${ListingStyle.cta}`}>
                        <Cta 
                          kind={pageData.cta_action_key_features} 
                          label={pageData.cta_title_features.text}
                          pageContext={pageContext}
                        />
                      </div>
                    )
                  }
                </div>
              </section>
            )
          }

          {
            accordionData.length > 1 && (
              <div className="container">
                <h2>{pageData.title2.text}</h2>
                <div dangerouslySetInnerHTML={{ __html: pageData.solutions_intro.html }}></div>
                <div className="plan-features">
                  <div className="row">
                    <div className="col-md-10 offset-md-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            {
                              accordionData.map((item, index) => {
                                return (
                                  <div 
                                    // className={`col-md-3 planHeader`} 
                                    className={`col-md-${(12 / accordionData.length)} planHeader`}
                                    key={`header-${index}`}
                                    style={{
                                      backgroundColor: item.color,
                                      borderRightColor: `${index === (accordionData.length - 1) ? item.color : '#f3f3f3'}`
                                    }}
                                  >
                                    <div 
                                      className="planTitle"
                                      onClick={() => {
                                        if (document.getElementsByClassName('accordion-item--opened').length) {
                                          document.getElementsByClassName('accordion-item--opened')[0].click();
                                        }
                                        document.getElementById(item.anchor).click();
                                        document.location.href = `#${item.anchor}`
                                      }}
                                    >{item.title}</div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    rows.map((item, rowindex) => {
                      return (
                        <React.Fragment>
                          {
                            item[0].text && (
                              <div className="row featureRow" key={`row-${rowindex}`}>
                                <div className="col-md-2 feature first">
                                  <p>{item[0].text}</p>
                                </div>
                                <div className="col-md-10 feature">
                                  <div className="row">
                                    {
                                      item.filter((cell, index) => index > 0).map((cell, cellindex) => {
                                        return (
                                          <div 
                                            // className={`col-md-3 planFeature`}
                                            className={`col-md-${(12 / item.filter((cell, index) => index > 0).length)} planFeature`}
                                            style={{color: cell.color}}
                                            key={`cell-${cellindex}`}
                                            dangerouslySetInnerHTML={{__html: cell.html}}
                                          >
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        </React.Fragment>
                      )
                    })
                  }
                </div>

                <Accordion data={accordionData} />
                <div className={`${ListingStyle.cta}`}>
                  <Cta 
                    kind={pageData.cta_action_key1} 
                    label={pageData.cta_title1.text}
                    pageContext={pageContext}
                  />
                </div>
              </div>
            )
          }
        </section>
        
        {
          (pageData.title_keyfeatures.text && pageData.list_keyfeatures.length > 1) && (
            <section>
              <div className="container">
                <h2>{pageData.title_keyfeatures.text}</h2>
                <div className={`${ListingStyle.intro}`} dangerouslySetInnerHTML={{ __html: pageData.intro_keyfeatures.html}}></div>
                <div className={`row ${ListingStyle.strategies}`}>
                  <div className="col-md-12">
                    {pageData.list_keyfeatures.map((i, index) => {
                      return (
                        <div key={`process-${index}`}>
                          <h3>{i.title_keyfeatures_list.text}</h3>
                          <div dangerouslySetInnerHTML={{ __html: i.description_keyfeatures_list.html}}></div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {
                  pageData.cta_action_key_keyfeatures && (
                    <div className={`${ListingStyle.cta}`}>
                      <Cta 
                        kind={pageData.cta_action_key_keyfeatures} 
                        label={pageData.cta_title_keyfeatures.text}
                        pageContext={pageContext}
                      />
                    </div>
                  )
                }
              </div>
            </section>
          )
        }

        {
          pageData.list.length > 1 && (
            <section>
              <div className="container">
                <h2>{pageData.title3.text}</h2>
                <div className={`${ListingStyle.intro}`} dangerouslySetInnerHTML={{__html: pageData.intro1.html}}></div>
                <div className="row">
                  {pageData.list.map((i, index) => {
                    return (
                      <div className={`col-md-4 ${ListingStyle.processCards}`} key={`process-${index}`}>
                        <div>
                          <h3>{i.title4.text}</h3>
                          <div dangerouslySetInnerHTML={{__html: i.description.html}}></div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className={`${ListingStyle.cta}`}>
                  <Cta 
                    kind={pageData.cta_action_key2} 
                    label={pageData.cta_title2.text}
                    pageContext={pageContext}
                  />
                </div>
              </div>
            </section>
          )
        }

        <section>
          <div className="container">
            {
              collaborationsItems.length > 0 && (
                <React.Fragment>
                  <h2>{_t(`they_trust_us`)}</h2>
                  <div className={`row ${ListingStyle.Companies}`}>
                    {collaborationsItems &&
                      collaborationsItems.map((data, index) => {
                        return (
                          <div 
                            className={`col-4 _companies ${ListingStyle.CompaniesContainer}`} key={`company-${index}`}
                            style={{ padding: '5px' }}
                          >
                            <Link to={`/${pageContext.langKey}/collaborations/${data.uid}/`}>
                              <img src={data.logo} alt={data.title} />
                            </Link>
                          </div>
                        )
                      })}
                  </div>
                </React.Fragment>
              )
            }

            <h2>{_t(`see_more_collaborations`)}</h2>
            <CollaborationsFilters {...pageContext} _t={staticPrismicTexts} />
            {
              pageData.cta_action_key4 && (
                <div className={`${ListingStyle.cta}`}>
                  <Cta 
                    kind={pageData.cta_action_key4}
                    label={pageData.cta_title4.text}
                    pageContext={pageContext}
                  />
                </div>
              )
            }
          </div>
        </section>
        
        {
          pageData.list1.length > 1 && (
            <section>
              <div className="container">
                <h2>{pageData.title4.text}</h2>
                <div className={`${ListingStyle.intro}`} dangerouslySetInnerHTML={{__html: pageData.intro2.html}}></div>
                <div className={`row ${ListingStyle.strategies}`}>
                  <div className="col-md-12">
                    {pageData.list1.map((i, index) => {
                      return (
                        <div key={`process-${index}`}>
                          <h3>{i.title5.text}</h3>
                          <div dangerouslySetInnerHTML={{__html: i.description.html}}></div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {
                  pageData.cta_action_key3 && (
                    <div className={`${ListingStyle.cta}`}>
                      <Cta 
                        kind={pageData.cta_action_key3}
                        label={pageData.cta_title3.text}
                        pageContext={pageContext}
                      />
                    </div>
                  )
                }
              </div>
            </section>
          )
        }


        
      </div>
    </Layout>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleModalCta: (state) => dispatch(handleModalCta(state)),
});
export default connect(null, mapDispatchToProps)(Collaborations);
export const pageQuery = graphql`
  query CollaborationsPage($uid: String!, $locale: String!) {
    prismicFeatureFiltersCollaborations(uid: { eq: $uid }, lang: {eq: $locale}) {
      lang
      uid
      data {
        # main
        anchor
        title {
          text
        }
        description
        intro {
          html
          text
        }
        title1 {
          text
        }
        banner {
          url
          alt
        }

        # benefits
        list2 {
          title2 {
            text
            html
          }
          description {
            html
            text
          }
        }
        cta_title {
          text
        }
        cta_action_key

        # solutions
        title2 {
          text
        }
        solutions_intro {
          html
        }
        table_columns {
          label_column
          header {
            text
          }
          color
          row_1_text {
            html
            text
          }
          row_2_text {
            html
            text
          }
          row_3_text {
            html
            text
          }
          row_4_text {
            html
            text
          }
          row_5_text {
            html
            text
          }
          #row_6_text {
          #  html
          #  text
          #}
          anchor_title {
            html
            text
          }
          anchor_description {
            html
            text
          }
        }
        cta_title1 {
          text
        }
        cta_action_key1

        # process
        title3 {
          text
        }
        intro1 {
          html
        }
        list {
          title4 {
            text
          }
          description {
            html
          }
        }
        cta_title2 {
          text
        }
        cta_action_key2

        # more
        title4 {
          text
        }
        intro2 {
          html
        }
        list1 {
          title5 {
            text
          }
          description {
            html
          }
        }
        cta_title3 {
          text
        }
        cta_action_key3

        # meta
        title5
        description
        image {
          url
        }

        # filters
        cta_title4 {
          text
        }
        cta_action_key4

        title_features {
          text
        }
        intro_features {
          html
        }
        list_features {
          description_features_list {
            html
          }
          title_features_list {
            text
          }
        }
        cta_title_features {
          text
        }
        cta_action_key_features {
          text
        }

        title_keyfeatures {
          text
        }
        intro_keyfeatures {
          html
        }
        list_keyfeatures {
          description_keyfeatures_list {
            html
          }
          title_keyfeatures_list {
            text
          }
        }
        cta_title_keyfeatures {
          text
        }
        cta_action_key_keyfeatures {
          text
        }
      }
    }
    collaborations: allPrismicPageCollaborations(filter: { lang: { eq: $locale} }) {
      edges { ...collaborations }
    }
    collaborationsItems: allPrismicContentCollaboration(filter: { 
      lang: { eq: $locale} 
    }) {
      edges {
        node {
          uid
          data {
            logo {
              url
            }
            title {
              text
            }
            filters {
              filter {
                document {
                  data {
                    label {
                      html
                      text
                    }
                    anchor
                  }
                }
              }
            }
          }
        }
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`