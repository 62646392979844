import React from 'react'

export default ({ kind, label, pageContext: { slug } }) => {
  const baseLink = 'https://www.wakatoon.com';
  const links = [
    `https://www.vyte.in/pierrickchabi/30min?em=Contexte%20:${label}%20(${baseLink}${slug})`,
    '/contact/'
  ];
  let link = null;

  switch (kind) {
    case 'contact':
      link = links[1];
      break;

    case 'demo':
      link = links[0];
      break;

    case 'meeting':
      link = links[0];
      break;

    default:
      link = links[1];
      break;
  }
  return (
    <React.Fragment>
      {
        link === '/contact/' ? (
          <a className={`button btn btn_default`} href={link}>{label}</a>
        ) : (
          <a className={`button btn btn_default`} href={link} target="_blank" rel="noopener noreferrer">{label}</a>
        )
      }
    </React.Fragment>
  )
}