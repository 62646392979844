import React, { useState } from 'react'
import { StaticQuery, graphql, navigate, Link } from 'gatsby'
import { connect } from 'react-redux'
import ListingStyle from '../../styles/pages/collaborationCategory.module.scss'
import Style from '../../styles/components/dataFilters.module.scss'

const FiltersWrapper = ({ collaborationsFilters, langKey, current }) => (
  <React.Fragment>
    {collaborationsFilters.length > 0 && (
      <React.Fragment>
        {collaborationsFilters.map((i, categoryFilterIndex) => {
          return (
            <div
              className={ListingStyle.tags}
              key={`category-filter-${categoryFilterIndex}`}
            >
              <h3 style={{ color: i.color }}>{i.name}</h3>
              <div className={ListingStyle.tagsContainer}>
                {i.filters.map((filter, filterIndex) => {
                  return (
                    <React.Fragment key={`filter-${filterIndex}`}>
                      {
                        filter.uid === current ? (
                          <span style={{color: '#000', cursor: 'default'}}>
                            {filter.label}
                          </span>
                        ) : (
                          <Link 
                            to={`/${langKey}/collaborations/${filter.uid}/`}
                            style={{
                              backgroundColor: `${i.color}`,
                              borderColor: `${i.color}`,
                              color: '#fff',
                            }}
                          >
                            {filter.label}
                          </Link>
                        )
                      }
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )}
  </React.Fragment>
)

const DesktopLayout = ({ collaborationsFilters, langKey, current }) => (
  <FiltersWrapper
    collaborationsFilters={collaborationsFilters}
    langKey={langKey}
    current={current}
  />
)
const MobileLayout = ({ collaborationsFilters, _t, langKey, current }) => {
  const [active, setActive] = useState(false)
  return (
    <React.Fragment>
      {
        current === 'collaborations' ? (
          <React.Fragment>
            <button
              className={`button btn btn_default ${Style.filtersButton}`}
              onClick={() => setActive(!active)}
            >
              {_t('Filtrer')}
            </button>
            <div
              className={`${Style.filtersFullscreen} ${active ? Style.active : ''}`}
            >
              <div className={Style.filtersHead}>
                <span>{_t('Filtrer')}</span>
                <button 
                  className={`button btn btn_default ${Style.exit}`}
                  onClick={() => setActive(!active)}
                >
                  OK
                </button>
              </div>
              <div className={Style.innerWrapper}>
                <FiltersWrapper
                  collaborationsFilters={collaborationsFilters}
                  langKey={langKey}
                  current={current}
                />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              className={`${Style.filtersMobile}`}
            >
              <div className={Style.innerWrapper}>
                <FiltersWrapper
                  collaborationsFilters={collaborationsFilters}
                  langKey={langKey}
                  current={current}
                />
              </div>
            </div>
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

class CollaborationsFilters extends React.Component {
  constructor() {
    super()
    this.state = {
      filters: [],
      categories: [],
      categoriesFilters: {}
    }
    this.filtersCategories = {};
    this.collaborationsFilters = [];
    this.collaborationsFiltersTest = [];
  }

  componentDidMount() {
    // const { tags } = this.props
    // const params = this.getParams()
    // if (typeof tags !== 'undefined') {
    //   this.toggleFilters(tags[0])
    // } else if (params.length > 0) {
    //   params.forEach(i => this.toggleFilters(i))
    // }
  }

  insertParam = (key, value) => {
    if (typeof window !== 'undefined') {
      if (!value) {
        window.history.replaceState(null, null, `?${key}=all`)
      } else {
        const { langKey, parent, uid } = this.props;
        if (typeof parent !== 'undefined') {
          navigate(`/${langKey}/${parent}/?filters=${uid}`);
        }
        window.history.replaceState(null, null, `?${key}=${encodeURI(value)}`)
      }
    }
  }

  getParams = () => {
    if (typeof window !== 'undefined') {
      const url = new URL(window.document.location)
      const filters = url.search.replace('?filters=', '')
      if (filters === 'all' || filters === '') {
        return []
      }
      return decodeURI(filters).split(',')
    }
    return []
  }

  toggleFilters = filter => {
    let filters = []
    let categoriesFilters = this.state.categoriesFilters
    let currentCategory = this.collaborationsFilters.filter((i) => {
      const hasFilter = i.filters.filter(f => f.anchor === filter).length;
      return hasFilter > 0 && i.uid;
    })[0].uid;
    const categoriesFiltered = Object.keys(categoriesFilters).filter(i => categoriesFilters[i] === filter);

    if (categoriesFiltered.length > 0) {
      categoriesFilters[currentCategory] = null;
    } else {
      categoriesFilters[currentCategory] = filter;
    }

    Object.keys(categoriesFilters).forEach((i) => {
      if (categoriesFilters[i]) {
        filters.push(categoriesFilters[i])
      }
    });

    const x = document.getElementsByClassName('_companies')

    if (filters.length > 0) {
      for (let i = 0; i < x.length; i++) {
        x[i].style.display = 'none'
      }
      const elements = document.getElementsByClassName(filters.join(' '));
      if (elements.length > 0) {
        for (let _i = 0; _i < elements.length; _i++) {
          elements[_i].style.display = 'block'
        }
      }
      this.insertParam('filters', filters.join(','))
    } else {
      for (let i = 0; i < x.length; i++) {
        x[i].style.display = 'block'
      }
      this.insertParam('filters', false)
    }

    this.setState({ filters, categoriesFilters: categoriesFilters })
  }

  _t = str => this.props._t[this.props._t.findIndex(item => item.key_text === str)].text.text

  render() {
    const { locale, viewportSize, langKey, uid } = this.props
    return (
      <StaticQuery
        query={settingsQuery}
        render={data => {

          const { featureFiltersCollaborations } = data
          featureFiltersCollaborations.edges.filter(edge => edge.node.lang === locale).forEach((filter) => {
            this.filtersCategories[filter.node.data.category.uid] = filter.node.data.category.document[0].data
          });
          
          /* init collaborations filters */
          this.collaborationsFilters = Object.keys(this.filtersCategories).map((item) => {
            return {
              uid: item,
              color: this.filtersCategories[item].color,
              name: this.filtersCategories[item].category_name.text,
              rank: this.filtersCategories[item].rank,
              filters: featureFiltersCollaborations.edges
              .filter(edge => edge.node.lang === locale)
              .filter(edge => edge.node.data.category.uid === item).map((filter) => {
                return {
                  uid: filter.node.uid,
                  anchor: filter.node.data.anchor,
                  label: filter.node.data.label.text,
                }
              })
            }
          });

          return (
            <React.Fragment>
              {viewportSize !== 'medium' && viewportSize !== 'small' ? (
                <DesktopLayout
                  collaborationsFilters={this.collaborationsFilters}
                  state={this.state}
                  langKey={langKey}
                  current={uid}
                  toggleFilters={this.toggleFilters}
                />
              ) : (
                <MobileLayout
                  collaborationsFilters={this.collaborationsFilters}
                  state={this.state}
                  langKey={langKey}
                  current={uid}
                  toggleFilters={this.toggleFilters}
                  _t={this._t}
                />
              )}
            </React.Fragment>
          )
        }}
      />
    )
  }
}

const mapStateToProps = state => ({
  ...{ viewportSize: state.viewport.viewportSize },
})
export default connect(mapStateToProps)(CollaborationsFilters)
const settingsQuery = graphql`
  query CollaborationsFiltersQuery {
    featureFiltersCollaborations: allPrismicFeatureFiltersCollaborations {
      edges {
        node {
          uid
          lang
          data {
            anchor
            label {
              text
            }
            category {
              uid
              document {
                data {
                  category_name {
                    text
                  }
                  color
                  rank
                }
              }
            }
          }
          uid
        }
      }
    }
  }
`
