import React from 'react'

export class Accordion extends React.Component {
  render () {
    const { data } = this.props
    return (
      <div {...{ className: 'wrapper' }}>
        <ul {...{ className: 'accordion-list' }}>
          {data.map((data, key) => {
            return (
              <li {...{ className: 'accordion-list__item', key }}>
                <AccordionItem {...data} />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

class AccordionItem extends React.Component {
  state = {
    opened: false
  }
  
  render () {
    const {
      props: {
        paragraph,
        title,
        subTitle,
        color,
        anchor
      },
      state: {
        opened
      }
    } = this
    
    return (
      <React.Fragment>
        {
          (paragraph && anchor) && (
            <div
              {...{
                className: `accordion-item, ${opened && 'accordion-item--opened'}`,
                onClick: () => { 
                  if (document.getElementsByClassName('accordion-item--opened').length) {
                    document.getElementsByClassName('accordion-item--opened')[0].click();
                  }
                  this.setState({ opened: !opened }) 
                }
              }}
              id={anchor}
            >
              <div style={{backgroundColor: color}} {...{ className: 'accordion-item__line' }}>
                <span {...{ className: 'accordion-item__icon' }}>
                  {opened ? '-' : '+'}
                </span>
                <span {...{ className: 'accordion-item__title' }}>
                  {title}
                </span>
              </div>

              <div {...{ className: 'accordion-item__inner' }}>
                <div {...{ className: 'accordion-item__content' }}>
                  <h3>{subTitle}</h3>
                  <div {...{ className: 'accordion-item__paragraph' }} dangerouslySetInnerHTML={{__html: paragraph}}></div>
                </div>
              </div>
            </div>
          )
        }
      </React.Fragment>
    )
  }
}